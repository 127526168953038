import { DOMAIN } from "./utils/Client";

// base URLs
const devBaseUrl = `https://api.${DOMAIN}`;
export const prodBaseUrl = `https://api.${DOMAIN}`;

const urls = {
//    DMT1Url: `${base}${DMT1}`,
// //  DMT1Url: `http://api.remittpe.com/DmtApiMulti.aspx`,
//   DMTExpressUrl: `${base}${DMTExpress}`,
//    DMTWithKYCUrl: `${base}${DMTWithKYC}`,
//  // DMTWithKYCUrl: `http://api.remittpe.com/Fullkycdmt.aspx`,
//   DMTBankitUrl: `${base}${DMTBankit}`,
//   getCategoriesInfoUrl: `https://apitranxt.${DOMAIN}/api/Operator/GetCategoryInfo`,
//   getAllMobileRechargeOperatorsUrl: `https://apitranxt.${DOMAIN}/api/Operator/GetAllOperators`,
};
export { urls };

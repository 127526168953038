import React, { Suspense, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { decryptAES128, encryptAES128, generateHashKey2, uniqueID2 } from "../utils/utilFuctions";

import Spinner from "./shared/Spinner";
import Aeps from "./AEPS/Aeps6";

import { GlobalContext } from "../Context/GlobalState";
import { SESSION_TIMER } from "../utils/Session";
import { BASE_URL_APISERVICES } from "../utils/Constants";

const AppRoutes = () => {
  const { removeUserInfo, user } = useContext(GlobalContext);

  const onPrompt = () => {
    // Fire a Modal Prompt
    console.log("onPrompt");
  };

  const onIdle = () => {
    // Close Modal Prompt
    // Do some idle action like log out your user
    removeUserInfo();
    checkSession();
  };

  const onActive = (event) => { };

  const onAction = (event) => { };

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: SESSION_TIMER,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const checkSession = async (sessionId) => {
    try {
      const hash = encryptAES128(`${user.sessionId}|${user.Mobile}`)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "token": hash
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/AEPS/yesbank/login`,
        requestOptions
      );
      const data = await res.json();
      const decryptedStr = decryptAES128(data.token)
      if (decryptedStr.toString().includes("|")) {
        const resArr = decryptedStr.toString().split("|");
        if (resArr.length > 0 && resArr[0] == 200) {

        } else {
          removeUserInfo();
        }
      } else {
      //  setError("Invalid Credentials.Please check your credentials again.");
      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log(user);
      checkSession("");
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // Disable right-click
      document.addEventListener("contextmenu", (e) => e.preventDefault());

      function ctrlShiftKey(e, keyCode) {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
      }

      document.onkeydown = (e) => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (
          e.keyCode === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
        )
          return false;
      };
    }
  }, []);

  

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {/* Retailer */}

        <Route exact path="/retailer/aeps" component={Aeps} />

        <Redirect to="/user-pages/login-2" />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;

import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
import swal from "sweetalert";
import { decryptAES128, generateHashKey2, twoDecimalPlaces } from "../utils/utilFuctions";

// get user if present in session storage

const accountDetails = JSON.parse(sessionStorage.getItem("accountDetails"));

// encryption soon
export const GlobalContext = createContext({
  Profileinfo: null,
  myAccountInfo: accountDetails,
  token: null,
  user: {},
  wallet: {},
  transactionLoader: false,
});

export const GlobalProvider = ({ children }) => {

  const user = sessionStorage.getItem("user");
  let user2 = {};
  let wallet2 = {}
  const decryptedStr = decryptAES128(user)
  if (decryptedStr.toString().includes("|")) {
    const resArr = decryptedStr.toString().split("|");
    if (resArr.length > 0 && resArr[0] == 200) {
      user2 = {
        "Id": "Y",
        "ReferId": resArr[1],
        "pk_user_id": resArr[2],
        "Mobile": resArr[3],
        "Email": resArr[5],
        "shop_name": resArr[6],
        "IsUserActive": "0",
        "walletBalance": twoDecimalPlaces(resArr[7]),
        "UserType": "2",
        "UserTypeDesc": "2",
        "Result": "Y",
        "f_onboard_status": "Y",
        "f_ekyc_status": "N",
        "Bumppy_onboard_status": "N",
        "Bumppy_ekyc_status": "N",
        "Bankit_onboard_status": "N",
        "Bankit_ekyc_status": "N",
        "sessionId": resArr[10]
      }
    //  console.log(user2);
      wallet2 = {
        "Id": resArr[1],
        "WalletBalance": twoDecimalPlaces(resArr[7]),
        "AEPSBalance": twoDecimalPlaces(resArr[7]),
        "todayEarning": 0,
        "todaySale": 0,
        "IsDmtEnable": 0,
        "dmtMessage": null,
        "Result": null
      }

    }
  }


  const [state, dispatch] = useReducer(AppReducer, {
    Profileinfo: null,
    myAccountInfo: accountDetails,
    token: null,
    user: user2,
    wallet: wallet2,
    transactionLoader: false,
  });

  // Actions for changing state

  function setTransactionLoaderState(loaderState) {
    dispatch({
      type: "SET_TRANSACTION_LOADER",
      payload: loaderState,
    });
  }

  function setToken(token) {
    dispatch({
      type: "SET_TOKEN",
      payload: token,
    });
  }
  function setProfileinfo(info) {
    console.log(info);
    dispatch({
      type: "SET_MY_ACCOUNT_INFO",
      payload: info,
    });
  }
  function setMyAccountInfo(info) {
    sessionStorage.setItem("accountDetails", JSON.stringify(info));
    dispatch({
      type: "SET_MY_ACCOUNT_INFO_DEVELOPER",
      payload: info,
    });
  }
  function logoutDeveloperLogin() {
    sessionStorage.removeItem("accountDetails");
    dispatch({
      type: "LOG_OUT_DEVELOPER",
      payload: null,
    });
  }
  function removeMyAccountInfo() {
    sessionStorage.removeItem("accountDetails");
    dispatch({
      type: "REMOVE_MY_ACCOUNT_INFO_DEVELOPER",
      payload: null,
    });
  }
  function setUserInfo(info) {
    sessionStorage.setItem("user", info);

    const decryptedStr = decryptAES128(info)
    if (decryptedStr.toString().includes("|")) {
      const resArr = decryptedStr.toString().split("|");
      if (resArr.length > 0 && resArr[0] == 200) {
        const user2 = {
          "Id": "Y",
          "ReferId": resArr[1],
          "pk_user_id": resArr[2],
          "Mobile": resArr[3],
          "Email": resArr[5],
          "shop_name": resArr[6],
          "IsUserActive": "0",
          "walletBalance": twoDecimalPlaces(resArr[7]),
          "UserType": "2",
          "UserTypeDesc": "2",
          "Result": "Y",
          "f_onboard_status": "Y",
          "f_ekyc_status": "N",
          "Bumppy_onboard_status": "N",
          "Bumppy_ekyc_status": "N",
          "Bankit_onboard_status": "N",
          "Bankit_ekyc_status": "N",
          "sessionId": resArr[10]
        }
        const wallet = {
          "Id": resArr[1],
          "WalletBalance": twoDecimalPlaces(resArr[7]),
          "AEPSBalance": twoDecimalPlaces(resArr[7]),
          "todayEarning": 0,
          "todaySale": 0,
          "IsDmtEnable": 0,
          "dmtMessage": null,
          "Result": null
        }

        dispatch({
          type: "SET_USER_INFO",
          payload: user2,
        });

        dispatch({
          type: "SET_WALLET",
          payload: wallet,
        });

      }
    }


  }
  function removeUserInfo() {
    sessionStorage.removeItem("user");
    dispatch({
      type: "REMOVE_USER_INFO",
      payload: null,
    });
  }

  function setWalletBalance(wallet) {
    sessionStorage.setItem("wallet", JSON.stringify(wallet));
    dispatch({
      type: "SET_WALLET",
      payload: wallet,
    });
  }

  const getWalletBalance = async () => {
    try {
      setTransactionLoaderState(true);
      const hash_key = await generateHashKey2(user.ReferId);
      var myHeaders = new Headers();
      myHeaders.append("HASH_KEY", hash_key);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "userid": user.ReferId
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const res = await fetch("https://apiservices.remittpe.com/api/Payment/wallet/getWalletBalance", requestOptions);
      const result = await res.json();
      if (result.status == "0") {
        //  setBalanceToShow(result.data.pg_settlement_bal);

        setWalletBalance({
          "Id": "BR1004",
          "WalletBalance": result.data.wallet_bal,
          "AEPSBalance": 72.99,
          "todayEarning": 0,
          "todaySale": 0,
          "IsDmtEnable": 0,
          "dmtMessage": null,
          "Result": null
        });
      }

      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.log(error);
    }
  };

  function refreshWallet() {
    getWalletBalance();
  }

  const checkWalletBeforeBBPSBillPay = async (amount, callback, callback2) => {
    //WalletBalance
    //wallet
    try {
    } catch (error) {
      swal("Insufficient Balance", `Transaction Amount ${amount}`, "error");
      console.log("error", error);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        token: state.token,
        myAccountInfo: state.myAccountInfo,
        user: state.user,
        Profileinfo: state.Profileinfo,
        setToken,
        setUserInfo,
        removeUserInfo,
        setProfileinfo,
        setMyAccountInfo,
        setWalletBalance,
        wallet: state.wallet,
        transactionLoader: state.transactionLoader,
        refreshWallet,
        checkWalletBeforeBBPSBillPay,
        setTransactionLoaderState,
        removeMyAccountInfo,
        logoutDeveloperLogin
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

import { DOMAIN, CLIENT_BASIC_AUTH } from "./utils/Client";

let client = `https://${DOMAIN}`;

let printDomain = `https://dashboard.bumppy.com`;

if (process.env.NODE_ENV === 'development') {
    client = `http://localhost:3000`;
    printDomain = `http://localhost:3000`;
}

export const CLIENT = client;

export const PRINT_BASE_URL = printDomain;

export const basicAuth = CLIENT_BASIC_AUTH

export const REPORT_PAGE_RECORD_COUNT = 10;

import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { Dropdown } from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import userAvtar from "../../../assets/images/user.png";
import pemoreLogo from "../../../assets/images/company/logo_square.png";
import "react-toastify/dist/ReactToastify.css";


const Navbar = () => {
  const history = useHistory();
  const {
    user,
    setWalletBalance,
    myAccountInfo,
    setProfileinfo,
    removeUserInfo,
    wallet,
    setUserInfo, setTransactionLoaderState
  } = useContext(GlobalContext);
  // mdShow
  const [mdShow, setMdShow] = useState(false);


  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }

  return (
    <GlobalContext.Consumer>
      {(state) =>
        state.user == null || state.user.UserType !== "2" ? (
          <Redirect to="/" />
        ) : (
          <nav className="navbar p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
              <Link className="navbar-brand brand-logo-mini" to="/">
                <img src={pemoreLogo} />
              </Link>
            </div>
            <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
              <button
                className="navbar-toggler align-self-center"
                type="button"
              >
                <Link to="/retailer/dashboard">
                  <img
                    src={require("../../../assets/images/company/navbar_logo.png")}
                    style={{
                      height: "2.5rem",
                      paddingRight: "1rem",
                      width: "auto",
                    }}
                  />
                </Link>
              </button>

              <ul className="navbar-nav navbar-nav-right">
                {state.user == null || state.user.ReferId == "BUMPPYR1001-" ? null : (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        // getWalletBalance();
                      }}
                    >
                      <div className="navbar-profile">
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "1.5rem",
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ paddingRight: "2px" }}>₹</span>
                          {` ${wallet
                            ? wallet.WalletBalance
                            : state.user.walletBalance
                            } `}
                          <span className="menu-icon">
                            <i className="mdi mdi-reload"></i>
                          </span>
                        </span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}

                {wallet && Number(wallet.todaySale) && (
                  <Dropdown alignRight as="li" className="nav-item">
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                    >
                      <div className="navbar-profile">
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            flexDirection: "row",
                          }}
                        >
                          <span style={{ paddingRight: "2px" }}>₹</span>
                          {`Loan Amount: ${wallet
                            ? wallet.todaySale
                            : state.user.todaySale
                            } `}
                        </span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown>
                )}

                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret"
                  >
                    <div className="navbar-profile">
                      <img
                        className="img-xs rounded-circle"
                        src={userAvtar}
                        alt="profile"
                        style={{ height: "3.4rem", width: "auto" }}
                      />
                      <p className="mb-0 d-none d-sm-block navbar-profile-name text-dark">
                        <Trans>
                          {state.user == null ? (
                            ""
                          ) : (
                            <div className="d-flex flex-column justify-content-center">
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right"
                              >
                                <strong style={{ fontWeight: "800" }}>
                                  {state.user.ReferId}
                                </strong>
                              </span>
                              <strong
                                style={{ fontWeight: "800" }}
                                className="text-right"
                              >
                                {`${state.user.shop_name}`}
                              </strong>
                              <span
                                style={{
                                  fontWeight: "600",
                                  fontSize: "0.8rem",
                                }}
                                className="text-right"
                              >
                                {`${state.user.Mobile }`}
                              </span>
                              <span
                                style={{ fontWeight: "800", fontSize: ".8rem" }}
                                className="text-right"
                              >
                                {`Retailer`}
                              </span>
                            </div>
                          )}
                        </Trans>
                      </p>
                      <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{ minWidth: "20rem" }}
                    className="navbar-dropdown preview-list navbar-profile-dropdown-menu"
                  >
                    <Dropdown.Item
                      className="dropdown-item preview-item"
                      onClick={(evt) => {
                        evt.preventDefault();
                        removeUserInfo();
                      }}
                    >
                      <div className="preview-thumbnail">
                        <span
                          class="fa fa-sign-out ml-3"
                          style={{ color: "#666666" }}
                        ></span>
                      </div>
                      <div className="preview-item-content">
                        <Trans>Logout</Trans>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>

              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                onClick={toggleOffcanvas}
              >
                <span className="mdi mdi-format-line-spacing"></span>
              </button>
            </div>
          </nav>
        )
      }
    </GlobalContext.Consumer>
  );
};

export default Navbar;

import { writeFile, utils } from "xlsx";
import crypto from "crypto";
import CryptoJS from "crypto-js";
import {
  REGEX_ONLY_ALPHA_NUMERIC,
  REGEX_ONLY_ALPHA_SPACE,
  REGEX_ONLY_NUMERIC,
} from "./CommonConfig";
import { secretKey } from "./Secret";

const address = require("address");
export function ExportToExcel(fileName, data) {
  let wb = utils.book_new();
  let ws = utils.json_to_sheet(data);
  utils.book_append_sheet(wb, ws, "sheet");
  writeFile(wb, `${fileName}.xlsx`);
}

export const createHash = (data) => {
  return crypto.createHash("md5").update(data).digest("hex");
};
export const encryptAES = (data) => {
  return Buffer.from(data).toString("base64");
};
export const decryptAES = (data) => {
  return btoa(data);
};


export const uniqueID = () => {
  const date = new Date();
  return `${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }${date.getFullYear()}${date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
    }${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}${date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()
    }`;
};

export const excelMapping = (data, columns) => {
  const arr = [];
  data.forEach((record) => {
    let obj = {};
    columns.forEach((key) => {
      obj[key.text] = record[key.dataField];
    });
    arr.push(obj);
  });
  return arr;
};

var a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
var b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const inWords = (num) => {
  let n;
  if ((num = num.toString()).length > 9) return "overflow";
  n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      "only "
      : "";
  return capitalizeFirstLetter(str);
};

export const now = (date = new Date()) => {
  return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
};

const months = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const dateFormatToDisplay = (date) => {
  if (date) {
    const d = new Date(date);

    let hours = d.getHours();
    let minutes = d.getMinutes();
    let ap = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, "0");
    let mergeTime = hours + ":" + minutes + " " + ap;
    return `${d.getDate()} ${months[d.getMonth()]
      } ${d.getFullYear()} ${mergeTime}`;
  } else {
    return "";
  }
};

export const getDiviceInfo = () => {
  "use strict";

  var module = {
    options: [],
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera,
    ],
    dataos: [
      { name: "Windows Phone", value: "Windows Phone", version: "OS" },
      { name: "Windows", value: "Win", version: "NT" },
      { name: "iPhone", value: "iPhone", version: "OS" },
      { name: "iPad", value: "iPad", version: "OS" },
      { name: "Kindle", value: "Silk", version: "Silk" },
      { name: "Android", value: "Android", version: "Android" },
      { name: "PlayBook", value: "PlayBook", version: "OS" },
      { name: "BlackBerry", value: "BlackBerry", version: "/" },
      { name: "Macintosh", value: "Mac", version: "OS X" },
      { name: "Linux", value: "Linux", version: "rv" },
      { name: "Palm", value: "Palm", version: "PalmOS" },
    ],
    databrowser: [
      { name: "Chrome", value: "Chrome", version: "Chrome" },
      { name: "Firefox", value: "Firefox", version: "Firefox" },
      { name: "Safari", value: "Safari", version: "Version" },
      { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
      { name: "Opera", value: "Opera", version: "Opera" },
      { name: "BlackBerry", value: "CLDC", version: "CLDC" },
      { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
    ],
    init: function () {
      var agent = this.header.join(" "),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        html = "",
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, "i");
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
          matches = string.match(regexv);
          version = "";
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + ".";
              } else {
                version += matches[j];
              }
            }
          } else {
            version = "0";
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: "unknown", version: 0 };
    },
  };

  var e = module.init();

  return {
    os_name: e.os.name,
    os_version: e.os.version,
    browser_name: e.browser.name,
    browser_version: e.browser.version,
    user_agent: navigator.userAgent,
    app_version: navigator.appVersion,
    platform: navigator.platform,
    vendor: navigator.vendor,
  };
};

export const getMyIPAddress = async () => {

  let dd = sessionStorage.getItem("deviceInfo");
  if (dd) {
    return JSON.parse(dd);
  }
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  try {
    const res = await fetch("https://geolocation-db.com/json", requestOptions);
    const data = await res.json();
    const ip = data.IPv4;
    const deviceInfo = getDiviceInfo();

    sessionStorage.setItem(
      "deviceInfo",
      JSON.stringify({
        ...deviceInfo,
        ip_address: ip,
        mac_address: uniqueID(),
        location: `${data.city},${data.state}`,
      })
    );
    return {
      ...deviceInfo,
      ip_address: ip,
      mac_address: uniqueID(),
      location: `${data.city},${data.state}`,
    };
  } catch (error) {
    const ip = '0.0.0.0';
    const deviceInfo = getDiviceInfo();

    sessionStorage.setItem(
      "deviceInfo",
      JSON.stringify({
        ...deviceInfo,
        ip_address: ip,
        mac_address: uniqueID(),
        location: `Test`,
      })
    );
    return {
      ...deviceInfo,
      ip_address: ip,
      mac_address: uniqueID(),
      location: `Test`,
    };
  }


};

export const onlyAlpha = (text) => {
  var letters = /^[A-Za-z ]+$/;
  if (text.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export const onlyAlphaNumeric = (text) => {
  const regex = new RegExp(REGEX_ONLY_ALPHA_NUMERIC);
  return regex.test(text);
};

export const onlyNumeric = (text) => {
  const regex = new RegExp(REGEX_ONLY_NUMERIC);
  return regex.test(text);
};

export const twoDecimalPlaces = (value) => {
  return Number(value).toFixed(2).toString();
};

export const dateFormatter = (date) => {
  return `${date.getFullYear()}-${date.getMonth() > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
};

export const regexValidation = (value, regex) => {
  const pattern = new RegExp(regex);
  console.table({ value, regex });
  return pattern.test(value);
};

export const formatDateDDMMYYHHMM = (date) => {
  const d = new Date(date);
  const hour = d.getHours();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const minutes = d.getMinutes();
  let hourToShow = hour % 12 || 12;
  return `${day > 9 ? day : "0" + day}-${month > 9 ? month : "0" + month
    }-${d.getFullYear()} ${hourToShow > 9 ? hourToShow : "0" + hourToShow}:${minutes > 9 ? minutes : "0" + minutes
    }
        ${hour < 12 ? " AM" : " PM"}`;
};

export const formatDateDDMMYY = (date) => {
  const d = new Date(date);
  const hour = d.getHours();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const minutes = d.getMinutes();
  let hourToShow = hour % 12 || 12;
  return `${day > 9 ? day : "0" + day}-${month > 9 ? month : "0" + month
    }-${d.getFullYear()}`;
};

export const addRemitterValidation = (value) => {
  if (value.length < 5) {
    return "Remitter Name should be atleat 5 character long";
  }
  if (
    value.startsWith("xyz") ||
    value.startsWith("asdf") ||
    value.startsWith("qwerty")
  ) {
    return "Remitter Name should not start with xyx, asdf, qwerty";
  }
  if (value.includes("Test") || value.includes("test")) {
    return "Remitter Name should not contains Test";
  }
  if (/(.)\1{2,}/.test(value)) {
    return "Remitter Name should not contains 2 continuosly repeat character";
  }
  return "";
};

export const validatePanCard = (panNumber) => {
  var panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  if (panPattern.test(panNumber)) {
    var fifthCharacter = panNumber.charAt(4);
    if (isLetter(fifthCharacter)) {
      return true;
    }
  }
  return false;
  function isLetter(character) {
    return character.match(/[A-Z]/i);
  }
};

export const validateIFSCCode = (ifscCode) => {
  ifscCode = ifscCode.trim().toUpperCase();
  var regex = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
  if (!regex.test(ifscCode)) {
    return false;
  }
  return true;
};

export const validateIFSCCode3 = (ifscCode) => {
  ifscCode = ifscCode.trim().toUpperCase();
  var regex = /^[A-Z][0][A-Z0-9]$/;
  if (!regex.test(ifscCode)) {
    return false;
  }
  return true;
};

export const validateIFSCCode2 = (ifscCode) => {
  ifscCode = ifscCode.trim().toUpperCase();
  var regex = /^[A-Z]{4}\d+$/;
  if (!regex.test(ifscCode)) {
    return false;
  }
  return true;
};

export const validateNumber = (number) => {
  number = number.trim().toUpperCase();
  var regex = /^\d+$/;
  if (!regex.test(number)) {
    return false;
  }
  return true;
};

export const uniqueID2 = () => {
  const date = new Date();
  return `${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}${date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }${date.getFullYear()}${date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
    }${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}${date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()
    }${date.getMilliseconds() > 99
      ? date.getMilliseconds()
      : date.getMilliseconds() > 9
        ? "0" + date.getMilliseconds()
        : "00" + date.getMilliseconds()
    }`;
};

export const getMacAddress = async () => {
  address(function (err, addrs) {
    console.log(addrs.ip, addrs.ipv6, addrs.mac, addrs);
    // return { ...deviceInfo, ip_address: ip, mac_address: "" };
  });

  address("vboxnet", function (err, addrs) {
    console.log(addrs.ip, addrs.ipv6, addrs.mac, addrs);
    //  return { ...deviceInfo, ip_address: ip, mac_address: "" };
  });
};

// Encryption

// Change this secret key to your own private key

export const encryptAES128 = (plainText) => {
  const iv = crypto.randomBytes(16); // Generate a random initialization vector (IV)
  const cipher = crypto.createCipheriv("aes-128-cbc", secretKey, iv);

  let encrypted = cipher.update(plainText, "utf8", "base64");
  encrypted += cipher.final("base64");

  // Combine the IV and the encrypted data
  const combinedBytes = Buffer.concat([iv, Buffer.from(encrypted, "base64")]);

  return combinedBytes.toString("base64");
};


export const decryptAES128 = (encryptedBase64) => {
  try {
    const encryptedBytes = Buffer.from(encryptedBase64, "base64");

    // Extract IV from the first 16 bytes
    const iv = encryptedBytes.slice(0, 16);
    const encryptedData = encryptedBytes.slice(16);

    const decipher = crypto.createDecipheriv("aes-128-cbc", secretKey, iv);

    let decrypted = decipher.update(encryptedData, "base64", "utf8");
    decrypted += decipher.final("utf8");

    return decrypted;
  } catch (error) {
   return  "";
  }

};

function formatDateNow(date = new Date()) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const generateHashKey = (userid) => {
  return encryptAES128(`${userid}|${formatDateNow()}|WEB`);
};

export const generateHashKey2 = async (userid) => {

  let res = await getMyIPAddress();
  console.log(res);
  // let res ="";
  if (navigator.geolocation) {
    let position = sessionStorage.getItem("position");
    if (position) {
      position = JSON.parse(position);
    } else {
      position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      console.log(position);
      sessionStorage.setItem(
        "position",
        JSON.stringify({
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        })
      );
    }

    const str = `${userid}|${formatDateNow()}|WEB|${position.coords.latitude}|${position.coords.longitude
      }|${res.ip_address}|${uniqueID2()}|${res.location}`;

    console.log(str);

    res = encryptAES128(str);
    console.log(res);
  }
  return res;
};

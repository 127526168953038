import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import { Collapse } from "react-bootstrap";
import {
  COMPANY_NAME,
  COMPANY_NAME_NAVBAR,
  OFFLINE_TEXT,
} from "../../utils/StringConstants";
import AEPSReportIcon from "../../../assets/svg/sidebar/aeps_report.svg";
import AEPSIcon from "../../../assets/svg/sidebar/aeps.svg";
import AEPSSettlementIcon from "../../../assets/svg/sidebar/aeps_1.svg";
import BankDetailscon from "../../../assets/svg/sidebar/bank_details.svg";
import BBPSReportIcon from "../../../assets/svg/sidebar/bbps_report.svg";
import BumppyWalletIcon from "../../../assets/svg/sidebar/bumppy_wallet.svg";
import DMTReportIcon from "../../../assets/svg/sidebar/dmt_report.svg";
import DMTIcon from "../../../assets/svg/sidebar/dmt.svg";
import LedgerIcon from "../../../assets/svg/sidebar/ledger.svg";
import RechargeHistoryIcon from "../../../assets/svg/sidebar/recharge_history.svg";
import ReportsIcon from "../../../assets/svg/sidebar/reports.svg";
import TopupRequestIcon from "../../../assets/svg/sidebar/topup_request.svg";
import TopupViewIcon from "../../../assets/svg/sidebar/topup_view.svg";
import VirtualReportIcon from "../../../assets/svg/sidebar/virtual_report.svg";
import WalletToWalletIcon from "../../../assets/svg/sidebar/wallet_to_wallet.svg";
import BBPSOffline from "../../../assets/svg/offline_bbps.svg";
const style = {};
const activeStyle = {};

class Sidebar extends Component {
  state = { services: [], verifiedKycData: null };

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ ...this.state, [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ ...this.state, [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ ...this.state, [i]: false });
      });
      this.setState({ ...this.state, [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    // this.accessInfo();
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    // Object.keys(this.state).forEach((i) => {
    //   this.setState({ [i]: false });
    // });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/report", state: "ReportMenuOpen" },
      { path: "/dmt", state: "DMTMenuOpen" },
      { path: "/offline", state: "HybridMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ ...this.state, [obj.state]: true });
      }
    });
  }
  //  Main Code
  accessInfo() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const user = JSON.parse(sessionStorage.getItem("user"));

    var raw = JSON.stringify({
      userid: user.ReferId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  }

  // END Main Code

  verificationData() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const user = JSON.parse(sessionStorage.getItem("user"));

      var raw = JSON.stringify({
        userid: user.pk_user_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
    } catch (error) { }
  }

  isServiceActive(idx) {
    let active = false;
    if (this.state.services) {
      this.state.services.forEach((menu) => {
        if (menu.pk_menu_id == idx && menu.status == "Y") {
          active = true;
        }
      });
    }
    return active;
  }

  render() {
    console.log(this.state);
    return (
      <nav
        style={{
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        }}
        className="sidebar sidebar-offcanvas"
        id="sidebar"
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <strong style={{ color: "#FFF" }}>{COMPANY_NAME_NAVBAR}</strong>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../../assets/images/company/logo_square.png")}
              alt="logo"
            />
          </a>
        </div>
        <ul className="nav" style={{ overflowY: "scroll", height: "100vh" }}>
          <li
            style={
              (this.isPathActive("/retailer/dashboard") ? activeStyle : style,
                { marginTop: "10px" })
            }
            className={
              this.isPathActive("/retailer/dashboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/retailer/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>
          {this.isServiceActive(2) && (
            <li
              style={
                this.isPathActive("/retailer/transactions") ? activeStyle : style
              }
              className={
                this.isPathActive("/retailer/transactions")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link className="nav-link" to="/retailer/transactions">
                <span className="menu-icon">
                  <i className="mdi mdi-home"></i>
                </span>
                <span className="menu-title">
                  <Trans>Transactions</Trans>
                </span>
              </Link>
            </li>
          )}
          <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
            <strong className="text-light">PAYMENT PRODUCTS</strong>
          </div>
          {/* 
          <li
            style={
              (this.isPathActive("/retailer/money-transfer")
                ? activeStyle
                : style,
              { marginTop: "10px" })
            }
            className={
              this.isPathActive("/retailer/money-transfer")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link
              className={`nav-link ${
                this.isServiceActive(1) ? "" : "text-muted"
              }`}
              to={
                this.isServiceActive(1)
                  ? "/retailer/money-transfer"
                  : this.props.location
              }
            >
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Money Transfer</Trans>
              </span>
            </Link>
          </li> */}
          {this.isServiceActive(2) && (
            <li
              style={this.isPathActive("/retailer/dmt") ? activeStyle : style}
              className={
                this.isPathActive("/retailer/dmt")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(2) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(2) ? "/retailer/dmt" : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={AEPSIcon} />
                </span>
                <span className="menu-title">DMT</span>
              </Link>
            </li>
          )}



          {/* <li
            style={
              this.isPathActive("/retailer/vendor-payment") ? activeStyle : style
            }
            className={
              this.isPathActive("/retailer/vendor-payment")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link
              className={`nav-link ${this.isServiceActive(39) ? "" : "text-muted"
                }`}
              to={
                this.isServiceActive(39)
                  ? "/retailer/vendor-payment"
                  : this.props.location
              }
            >
              <span className="menu-icon">
                <img style={{ height: "1.2rem" }} src={AEPSIcon} />
              </span>
              <span className="menu-title">Vendor Payment</span>
            </Link>
          </li> */}

          {this.isServiceActive(3) && (
            <li
              style={
                this.isPathActive("/retailer/e-suvidha") ? activeStyle : style
              }
              className={
                this.isPathActive("/retailer/e-suvidha")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(3) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(3)
                    ? "/retailer/e-suvidha"
                    : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={AEPSIcon} />
                </span>
                <span className="menu-title">E Suvidha</span>
              </Link>
            </li>
          )}


          {this.isServiceActive(5) && (
            <li
              style={this.isPathActive("/retailer/aeps") ? activeStyle : style}
              className={
                this.isPathActive("/retailer/aeps")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(5) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(5) ? "/retailer/aeps" : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={AEPSIcon} />
                </span>
                <span className="menu-title">AEPS</span>
              </Link>
            </li>
          )}




          {/* main code data binding */}
          {this.isServiceActive(6) && (
            <li
              style={
                this.isPathActive("/retailer/aeps-settlement")
                  ? activeStyle
                  : style
              }
              className={
                this.isPathActive("/retailer/aeps-settlement")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(6) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(6)
                    ? "/retailer/aeps-settlement"
                    : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={AEPSSettlementIcon} />
                </span>
                <span className="menu-title">AEPS Settlement</span>
              </Link>
            </li>
          )}


          {this.isServiceActive(34) && (
            <li
              style={
                this.isPathActive("/retailer/pg-settlement")
                  ? activeStyle
                  : style
              }
              className={
                this.isPathActive("/retailer/pg-settlement")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(34) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(34)
                    ? "/retailer/pg-settlement"
                    : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={AEPSSettlementIcon} />
                </span>
                <span className="menu-title">PG Direct Settlement</span>
              </Link>
            </li>
          )}

          {/* main code data binding */}
          {/* Offline sidebar  */}
          {this.isServiceActive(7) && (
            <li
              style={
                this.isPathActive("/retailer/offline_bill_payment")
                  ? activeStyle
                  : style
              }
              className={
                this.isPathActive("/retailer/offline_bill_payment")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(7) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(7)
                    ? "/retailer/offline_bill_payment"
                    : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={BBPSOffline} />
                </span>
                <span className="menu-title">Hybrid Bill Payment</span>
              </Link>
            </li>

          )}


          {/* Online sidebar */}

          {this.isServiceActive(8) && (
            <li
              style={
                this.isPathActive("/retailer/bill_payment") ? activeStyle : style
              }
              className={
                this.isPathActive("/retailer/bill_payment")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(8) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(8)
                    ? "/retailer/bill_payment"
                    : this.props.location
                }
              >
                <span className="menu-icon">
                  <img
                    style={{ height: "1.2rem" }}
                    src={require("../../../assets/images/bbps/bill.png")}
                  />
                </span>
                <span className="menu-title">Bill Payment</span>
              </Link>
            </li>
          )}


          {/* <li
            style={this.isPathActive("/retailer/flight") ? activeStyle : style}
            className={
              this.isPathActive("/retailer/flight")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link
              className={`nav-link ${
                this.isServiceActive(8) ? "" : "text-muted"
              }`}
              to={
                this.isServiceActive(8)
                  ? "/retailer/flight"
                  : this.props.location
              }
            >
              <span className="menu-icon">
                <img style={{ height: "1.2rem" }} src={FlightBooking} />
              </span>
              <span className="menu-title">Flight Booking</span>
            </Link>
          </li> */}

          {/* <li
            style={this.isPathActive("/retailer/lic") ? activeStyle : style}
            className={
              this.isPathActive("/retailer/lic")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/retailer/lic">
              <span className="menu-icon">
                <img style={{ height: "1.2rem" }} src={LifeInsuranceIcon} />
              </span>
              <span className="menu-title">LIC</span>
            </Link>
          </li> */}

          {this.isServiceActive(35) && (
            <li
              style={
                this.isPathActive("/retailer/e_pan")
                  ? activeStyle
                  : style
              }
              className={
                this.isPathActive("/retailer/e_pan")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(35) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(35)
                    ? "/retailer/e_pan"
                    : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={BBPSOffline} />
                </span>
                <span className="menu-title">E Pan</span>
              </Link>
            </li>
          )}

          {this.isServiceActive(37) && (
            <li
              style={
                this.isPathActive("/retailer/credit_card_bill_payment")
                  ? activeStyle
                  : style
              }
              className={
                this.isPathActive("/retailer/credit_card_bill_payment")
                  ? "nav-item menu-items active"
                  : "nav-item menu-items"
              }
            >
              <Link
                className={`nav-link ${this.isServiceActive(37) ? "" : "text-muted"
                  }`}
                to={
                  this.isServiceActive(37)
                    ? "/retailer/credit_card_bill_payment"
                    : this.props.location
                }
              >
                <span className="menu-icon">
                  <img style={{ height: "1.2rem" }} src={BBPSOffline} />
                </span>
                <span className="menu-title">Credit Card Bill Payment</span>
              </Link>
            </li>

          )}





          {/* <li
            style={
              this.isPathActive("/retailer/LoansAndMore") ? activeStyle : style
            }
            className={
              this.isPathActive("/retailer/LoansAndMore")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/retailer/LoansAndMore">
              <span className="menu-icon">
                <img
                  style={{ height: "1.2rem" }}
                  src={require("../../../assets/images/bbps/loanrepayment.png")}
                />
              </span>
              <span className="menu-title">Loans & More</span>
            </Link>
          </li> */}

          <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
            <strong className="text-light">BANKING PRODUCTS</strong>
          </div>

          <li
            className={
              this.isPathActive("/retailer/wallet")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.WalletMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("WalletMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <img style={{ height: "1.2rem" }} src={WalletToWalletIcon} />
              </span>
              <span className="menu-title">
                <Trans>{`${COMPANY_NAME} WALLET`}</Trans>
              </span>
              <i
                className="menu-arrow"
                style={{ fontSize: "1.6rem", color: "#1746A2" }}
              ></i>
            </div>

            <Collapse
              in={
                this.isPathActive("/retailer/wallet")
                  ? true
                  : this.state.WalletMenuOpen
              }
            >
              <ul className="nav flex-column sub-menu">
                {this.isServiceActive(10) && (
                  <li
                    style={
                      this.isPathActive("/retailer/topup_request")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/topup_request")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(10) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(10)
                          ? "/retailer/topup_request"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={TopupRequestIcon}
                        />
                      </span>
                      <span className="menu-title">Topup Request</span>
                    </Link>
                  </li>

                )}

                {this.isServiceActive(32) && (
                  <li
                    style={
                      this.isPathActive("/retailer/credit-request")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/credit-request")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(32) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(32)
                          ? "/retailer/credit-request"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={TopupRequestIcon}
                        />
                      </span>
                      <span className="menu-title">Credit Request</span>
                    </Link>
                  </li>

                )}

                {this.isServiceActive(11) && (
                  <li
                    style={
                      this.isPathActive("/retailer/virtual_report")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/virtual_report")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(11) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(11)
                          ? "/retailer/virtual_report"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={VirtualReportIcon}
                        />
                      </span>
                      <span className="menu-title">Virtual Report</span>
                    </Link>
                  </li>

                )}

                {this.isServiceActive(12) && (
                  <li
                    style={
                      this.isPathActive("/retailer/topup_view")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/topup_view")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(12) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(12)
                          ? "/retailer/topup_view"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img style={{ height: "1.2rem" }} src={TopupViewIcon} />
                      </span>
                      <span className="menu-title">Topup View</span>
                    </Link>
                  </li>
                )}


                {this.isServiceActive(14) && (
                  <li
                    style={
                      this.isPathActive("/retailer/bank_details")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/bank_details")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(14) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(14)
                          ? "/retailer/bank_details"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img style={{ height: "1.2rem" }} src={BankDetailscon} />
                      </span>
                      <span className="menu-title">Bank Details</span>
                    </Link>
                  </li>
                )}

                {this.isServiceActive(15) && (
                  <li
                    style={
                      this.isPathActive("/retailer/wallet_to_wallet_transfer")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/wallet_to_wallet_transfer")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(15) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(15)
                          ? "/retailer/wallet_to_wallet_transfer"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={WalletToWalletIcon}
                        />
                      </span>
                      <span className="menu-title">
                        Wallet to Wallet Transfer
                      </span>
                    </Link>
                  </li>
                )}





              </ul>
            </Collapse>
          </li>
          {/* <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
            <strong>Reports</strong>
          </div> */}
          <li
            className={
              this.isPathActive("/retailer/report")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.ReportMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("ReportMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <img style={{ height: "1.2rem" }} src={ReportsIcon} />
              </span>
              <span className="menu-title">
                <Trans>Reports</Trans>
              </span>
              <i
                className="menu-arrow"
                style={{ fontSize: "1.6rem", color: "#1746A2" }}
              ></i>
            </div>

            <Collapse
              in={
                this.isPathActive("/retailer/report")
                  ? true
                  : this.state.ReportMenuOpen
              }
            >
              <ul className="nav flex-column sub-menu">

                {this.isServiceActive(16) && (
                  <li
                    style={
                      this.isPathActive("/retailer/reports/dmt")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/reports/dmt")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(16) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(16)
                          ? "/retailer/reports/dmt"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img style={{ height: "1.2rem" }} src={DMTReportIcon} />
                      </span>
                      <span className="menu-title">DMT Reports</span>
                    </Link>
                  </li>
                )}

                {this.isServiceActive(17) && (
                  <li
                    style={
                      this.isPathActive("/retailer/reports/aeps")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/reports/aeps")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(17) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(17)
                          ? "/retailer/reports/aeps"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img style={{ height: "1.2rem" }} src={AEPSReportIcon} />
                      </span>
                      <span className="menu-title">AEPS Reports</span>
                    </Link>
                  </li>
                )}


                {/* <li
                  style={
                    this.isPathActive("/retailer/reports/aeps-reconcile")
                      ? activeStyle
                      : style
                  }
                  className={
                    this.isPathActive("/retailer/reports/aeps-reconcile")
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link
                    className="nav-link"
                    to="/retailer/reports/aeps-reconcile"
                  >
                    <span className="menu-icon">
                      <img style={{ height: "1.2rem" }} src={AEPSReportIcon} />
                    </span>
                    <span className="menu-title">AEPS Reconcile Reports</span>
                  </Link>
                </li> */}

                {this.isServiceActive(18) && (
                  <li
                    style={
                      this.isPathActive("/retailer/bbps_history")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/bbps_history")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(18) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(18)
                          ? "/retailer/bbps_history"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img style={{ height: "1.2rem" }} src={BBPSReportIcon} />
                      </span>
                      <span className="menu-title">BBPS History</span>
                    </Link>
                  </li>
                )}

                {this.isServiceActive(19) && (
                  <li
                    style={
                      this.isPathActive("/retailer/recharge_history")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/recharge_history")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link
                      className={`nav-link ${this.isServiceActive(19) ? "" : "text-muted"
                        }`}
                      to={
                        this.isServiceActive(19)
                          ? "/retailer/recharge_history"
                          : this.props.location
                      }
                    >
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={RechargeHistoryIcon}
                        />
                      </span>
                      <span className="menu-title">Recharge History</span>
                    </Link>
                  </li>
                )}

                {this.isServiceActive(43) && (

                  <li
                    style={
                      this.isPathActive("/retailer/reports/pg")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/reports/pg")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className={`nav-link `} to={"/retailer/reports/pg"}>
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={RechargeHistoryIcon}
                        />
                      </span>
                      <span className="menu-title">PG Report</span>
                    </Link>
                  </li>
                )}



                {this.isServiceActive(44) && (
                  <li
                    style={
                      this.isPathActive("/retailer/reports/pg_settlement_report")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/reports/pg_settlement_report")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className={`nav-link `} to={"/retailer/reports/pg_settlement_report"}>
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={RechargeHistoryIcon}
                        />
                      </span>
                      <span className="menu-title">PG Settlement Report</span>
                    </Link>
                  </li>
                )}

                {this.isServiceActive(45) && (

                  <li
                    style={
                      this.isPathActive("/retailer/reports/epan_report")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/reports/epan_report")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className={`nav-link `} to={"/retailer/reports/epan_report"}>
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={RechargeHistoryIcon}
                        />
                      </span>
                      <span className="menu-title">E Pan Report</span>
                    </Link>
                  </li>
                )}

                {this.isServiceActive(47) && (
                  <li
                    style={
                      this.isPathActive("/retailer/reports/credit_card_bill_payment_report")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/reports/credit_card_bill_payment_report")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className={`nav-link `} to={"/retailer/reports/credit_card_bill_payment_report"}>
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={RechargeHistoryIcon}
                        />
                      </span>
                      <span className="menu-title">Credit Card Bill Report</span>
                    </Link>
                  </li>

                )}




                {this.isServiceActive(46) && (
                  <li
                    style={
                      this.isPathActive("/retailer/reports/fund_hold_report")
                        ? activeStyle
                        : style
                    }
                    className={
                      this.isPathActive("/retailer/reports/fund_hold_report")
                        ? "nav-item menu-items active"
                        : "nav-item menu-items"
                    }
                  >
                    <Link className={`nav-link `} to={"/retailer/reports/fund_hold_report"}>
                      <span className="menu-icon">
                        <img
                          style={{ height: "1.2rem" }}
                          src={RechargeHistoryIcon}
                        />
                      </span>
                      <span className="menu-title">Fund Hold Report</span>
                    </Link>
                  </li>

                )}






                <li
                  style={
                    this.isPathActive("/retailer/ledger") ? activeStyle : style
                  }
                  className={
                    this.isPathActive("/retailer/ledger")
                      ? "nav-item menu-items active"
                      : "nav-item menu-items"
                  }
                >
                  <Link
                    className={`nav-link ${this.isServiceActive(20) ? "" : "text-muted"
                      }`}
                    to={
                      this.isServiceActive(20)
                        ? "/retailer/ledger"
                        : this.props.location
                    }
                  >
                    <span className="menu-icon">
                      <img style={{ height: "1.2rem" }} src={LedgerIcon} />
                    </span>
                    <span className="menu-title">Ledger</span>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
            <strong className="text-light">Tools</strong>
          </div>

          {/* <li
            style={
              (this.isPathActive("/retailer/income-calculator")
                ? activeStyle
                : style,
                { marginTop: "10px" })
            }
            className={
              this.isPathActive("/retailer/income-calculator")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/retailer/income-calculator">
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Income Calculator</Trans>
              </span>
            </Link>
          </li> */}

          <div style={{ padding: ".5rem 1rem", margin: "0px" }}>
            <strong className="text-light">Developer Options</strong>
          </div>

          <li
            style={
              (this.isPathActive("/retailer/developer") ? activeStyle : style,
                {})
            }
            className={
              this.isPathActive("/retailer/developer")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link
              className={`nav-link ${this.isServiceActive(21) ? "" : "text-muted"
                }`}
              to={
                this.isServiceActive(21)
                  ? "/retailer/developer"
                  : this.props.location
              }
            >
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Profile</Trans>
              </span>
            </Link>
          </li>

          <li
            style={
              (this.isPathActive("/retailer/api-doc") ? activeStyle : style, {})
            }
            className={
              this.isPathActive("/retailer/api-doc")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link
              className={`nav-link ${this.isServiceActive(22) ? "" : "text-muted"
                }`}
              to={
                this.isServiceActive(22)
                  ? "/retailer/api-doc"
                  : this.props.location
              }
            >
              <span className="menu-icon">
                <i className="mdi mdi-home"></i>
              </span>
              <span className="menu-title">
                <Trans>Api Doc</Trans>
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.accessInfo();
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);

import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { GlobalContext } from "../Context/GlobalState";
import { urls } from "../URLs";
import crypto from "crypto";
import { ContextMenuTrigger } from "react-contextmenu";
import { generateCaptcha } from "../utils/GenerateCaptcha";
import {
  decryptAES,
  decryptAES128,
  encryptAES128,
  generateHashKey2,
} from "../utils/utilFuctions";
import {
  BASE_URL_APISERVICES,
} from "../utils/Constants";
import Error404 from "../error-pages/Error404";
import FoodIcon from "../../assets/svg/food.svg";
import PaymentIcon from "../../assets/svg/payment.svg";
import TravelIcon from "../../assets/svg/travel.svg";
import CompanyLogo from "../../assets/svg/logo.svg";
import MediaLogo from "../../assets/svg/media.svg";
import { auth } from "../utils/firebase/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";

export default function Login5() {
  const [signinDisable, setSigninDisable] = useState(false);
  const history = useHistory();
  const { login: loginUrl } = urls;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [contry, setCountry] = useState("+91");
  const [error, setError] = useState("");
  const [textOtp, setTextOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [storedUser, setStoredUser] = useState(null);
  const [resendOtpEnable, setResendOtpEnable] = useState(false);
  const [passShow, setPassShow] = useState(false);
  const [generatedCaptcha, setGeneratedCaptcha] = useState(generateCaptcha());
  const [seconds, setSeconds] = useState(60);
  const { setUserInfo, user, setTransactionLoaderState, setWalletBalance } =
    useContext(GlobalContext);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  const [formErrors2, setFormErrors2] = useState({
    otp: "",
  });

  // NEW OTP FLOW
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => { },
          "expired-callback": () => { },
        },
        auth
      );
      onSignup();
    }
  }

  function onSignup() {
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    console.log(appVerifier);

    const formatPh = contry + userName;
    console.log(formatPh);

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success("OTP sended successfully!");
        setOtpSent(true);
        setResendOtpEnable(false);
        setSeconds(60);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(textOtp)
      .then(async (res) => {
        setUserInfo(storedUser);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /// END

  const createHash = () => {
    return crypto.createHash("md5").update(password).digest("hex");
  };

  const loadCaptchaEnginge = (length) => {
    setGeneratedCaptcha(generateCaptcha());
  };

  const validate = async (callback) => {
    let errors = {};
    if (userName.length < 8) {
      errors.username = "please enter valid mobile number";
    }
    if (password.length < 4) {
      errors.password = "please enter valid password";
    }

    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      secureLogin()

    } else {
      setSigninDisable(false);
      setFormErrors(errors);
    }
  };

  const validate2 = () => {
    let errors = {};
    if (textOtp.length < 6) {
      errors.otp = "please enter valid OTP";
    }
    setFormErrors2(errors);
    if (Object.keys(errors).length === 0) {
      if (contry == "+91") {
        login2("", (user) => {
         // setUserInfo(user);
        });
      } else {
        onOTPVerify();
      }
    } else {
      setFormErrors2(errors);
    }
  };

  const secureLogin = async () => {
    try {
      const hash = encryptAES128(`${userName}|${password}`)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "token": hash
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_APISERVICES}/api/AEPS/yesbank/login`,
        requestOptions
      );
      const data = await res.json();
      const decryptedStr = decryptAES128(data.token)
      if (decryptedStr.toString().includes("|")) {
        const resArr = decryptedStr.toString().split("|");
        if (resArr.length > 0 && resArr[0] == 200) {

          setUserInfo(data.token);
        } else {
          setError("Invalid Credentials.Please check your credentials again.");
        }
      } else {
        setError("Invalid Credentials.Please check your credentials again.");
      }

    } catch (error) {
      console.log(error);
    }
  }

  //
  const login2 = async (userType, callback) => {
    const raw = "";
    const hash_key = await generateHashKey2(userName);
    var myHeaders = new Headers();
    myHeaders.append("HASH_KEY", hash_key);
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
     
    } catch (error) {
      setTransactionLoaderState(false);
      console.log("error", error);
    }
  };


  const goToForgetPassword = () => history.push("/forget-password");

  const showPasswordClick = () => {
    setPassShow(!passShow);
  };

  useEffect(() => {
    if (seconds === 0) {
      setResendOtpEnable(true);
    } else {
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [seconds]);

  useEffect(() => {
    if (signinDisable) {
      validate();
    }
  }, [signinDisable]);

  const resendOtpText = "Time Remaining: ";

  const minutes = Math.floor(seconds / 60);
  const formattedSeconds = String(seconds % 60).padStart(2, "0");
  const formattedTime = `${resendOtpText}${minutes}:${formattedSeconds}`;

  return (
    <GlobalContext.Consumer>
      {(context) =>
        context.user == null || Object.keys(context.user).length ==0 ? (
          <div
            className="m-0 p-0"
            style={{
              height: "100vh",
              background: "linear-gradient(to right, #EBEEF1, #EBEEF1)",
            }}
          >
            <Toaster toastOptions={{ duration: 4000 }} />
            <div id="recaptcha-container"></div>
            <div className="row justify-content-center">
              <img
                src={CompanyLogo}
                style={{ height: "3rem", width: "auto", color: "#EC5665" }}
                className="mx-1"
              />
            </div>

            <div className="row">
              <div className="row col-lg-4 col-md-8 col-sm-10 mx-auto justify-content-center align-items-center">
                <div className="d-flex px-0 py-0 align-items-center">
                  <img
                    src={PaymentIcon}
                    style={{
                      height: "1.8rem",
                      width: "1.8rem",
                      color: "#EC5665",
                      background: "#04A5A4",
                      padding: "4px",
                      borderRadius: "1rem",
                    }}
                    className="mx-1"
                  />
                  <span
                    style={{
                      fontSize: ".8rem",
                    }}
                  >
                    {" "}
                    Payments
                  </span>
                </div>
                {/* <div className="d-flex px-3 py-0 align-items-center">
                  <img
                    src={TravelIcon}
                    style={{
                      height: "1.8rem",
                      width: "1.8rem",
                      color: "#EC5665",
                      background: "#2AA01C",
                      padding: "4px",
                      borderRadius: "1rem",
                    }}
                    className="mx-1"
                  />
                  <span
                    style={{
                      fontSize: ".8rem",
                    }}
                  >
                    {" "}
                    Travel
                  </span>
                </div> */}
                <div className="d-flex px-3 py-0 align-items-center">
                  <img
                    src={FoodIcon}
                    style={{
                      height: "1.8rem",
                      width: "1.8rem",
                      color: "#EC5665",
                      background: "#D52A1E",
                      padding: "4px",
                      borderRadius: "1rem",
                    }}
                    className="mx-1"
                  />
                  <span
                    style={{
                      fontSize: ".8rem",
                    }}
                  >
                    {" "}
                    Food
                  </span>
                </div>
                <div className="d-flex px-3 py-0 align-items-center">
                  <img
                    src={MediaLogo}
                    style={{
                      height: "1.8rem",
                      width: "1.8rem",
                      color: "#EC5665",
                      background: "#2AA01C",
                      padding: "4px",
                      borderRadius: "1rem",
                    }}
                    className="mx-1"
                  />
                  <span
                    style={{
                      fontSize: ".8rem",
                    }}
                  >
                    {" "}
                    Media
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-lg-4 col-md-8 col-sm-10 mx-auto"
                style={{ maxWidth: "30rem" }}
              >
                <div
                  className="card text-left p-0"
                  style={{ border: "1px solid #c7c7c7" }}
                >
                  <h5 className="font-weight-bold mx-4 my-2 text-center">
                    Sign in to continue
                  </h5>
                  <form className="px-4 py-2">
                    {/* Password show */}
                    <div className="form-group">
                      <label>Mobile number</label>
                      <div className="input-group input-group-sm">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0 no-glow">
                            <select
                              onChange={(e) => {
                                setCountry(e.target.value);
                              }}
                              style={{
                                // padding: "4px",
                                border: "none",
                                outline: "none",
                              }}
                            >
                              {["+91", "+1"].map((en) => (
                                <option>{en}</option>
                              ))}
                            </select>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg border-left-0 no-glow"
                          id="exampleInputEmail"
                          placeholder="Username"
                          onChange={(e) => {
                            setError("");
                            setUserName(e.target.value);
                          }}
                        />
                      </div>
                      <p className="text-danger text-left p-0">
                        {formErrors.username}
                      </p>
                    </div>

                    {/* <div className="form-group">
                      <label>Mobile Number</label>
                      <div className="input-group input-group-sm">
                        <PhoneInput

                          country={"in"}
                          value={userName}
                          onChange={setUserName}
                        />
                      </div>
                      <p className="text-danger text-left p-0">
                        {formErrors.username}
                      </p>
                    </div> */}
                    <div className="form-group">
                      <label>Password</label>
                      <div className="input-group input-group-sm">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0 no-glow">
                            <i className="mdi mdi-lock-outline text-primary"></i>
                          </span>
                        </div>
                        <input
                          type={passShow ? "text" : "password"}
                          className="form-control form-control-lg border-left-0 border-right-0 no-glow"
                          id="exampleInputPassword"
                          placeholder="Password"
                          onChange={(e) => {
                            setError("");
                            setPassword(e.target.value);
                          }}
                        />
                        <div className="input-group-prepend bg-transparent">
                          <span
                            className="input-group-text bg-transparent border-left-0 no-glow"
                            onClick={showPasswordClick}
                            style={{ borderRadius: "4px" }}
                          >
                            <i
                              className={`mdi text-primary ${passShow ? "mdi-eye" : "mdi-eye-off"
                                }`}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <p className="text-danger text-left p-0">
                        {formErrors.password}
                      </p>
                    </div>

                    {otpSent && (
                      <div className="row m-0 p-0">
                        <div className="col-12 p-0 m-0">
                          <div className="form-group m-0">
                            {/* <label>OTP</label> */}
                            <div className="input-group input-group-sm">
                              {/* <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0 no-glow">
                                  <i className="mdi mdi-lock-outline text-primary"></i>
                                </span>
                              </div> */}
                              <input
                                type=""
                                className="form-control form-control-sm no-glow"
                                id="exampleInputPassword"
                                placeholder="Enter OTP"
                                onChange={(e) => {
                                  setTextOtp(e.target.value);
                                }}
                              />
                            </div>
                            <p className="text-danger text-left p-0">
                              {formErrors2.otp}
                            </p>
                          </div>
                        </div>
                        <div className="col-12 row m-0 p-0">
                          <div
                            className="col-6 d-flex align-items-center p-0"
                            style={{ fontSize: "0.9rem" }}
                          >
                            {formattedTime}
                          </div>
                          <div className="col-6 p-0">
                            <button
                              disabled={signinDisable}
                              style={{ backgroundColor: "#00A452" }}
                              className="rounded-0 btn btn-block btn-primary m-0 btn-lg"
                              onClick={(e) => {
                                e.target.disabled = true;
                                setSigninDisable(true);
                              }}
                            >
                              Resend OTP
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="my-2 d-flex justify-content-between align-items-center">
                      <div className="form-check">
                        <label className="form-check-label text-muted">
                          <input type="checkbox" className="form-check-input" />
                          <i className="input-helper"></i>
                          Keep me signed in
                        </label>
                      </div>
                      <a
                        href="!#"
                        onClick={(event) => {
                          event.preventDefault();
                          goToForgetPassword();
                        }}
                        className="auth-link text-muted"
                      >
                        Forgot password?
                      </a>
                    </div>
                    {error && <Invalid text={error} />}
                    <div className="mt-3">
                      {otpSent ? (
                        <div
                          style={{ backgroundColor: "#00A452" }}
                          className="rounded-0 btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          onClick={validate2}
                        >
                          Sign In
                        </div>
                      ) : (
                        <div
                          disabled={signinDisable}
                          style={{ backgroundColor: "#00A452" }}
                          className="rounded-0 btn btn-block btn-primary p-2 font-weight-medium auth-form-btn"
                          onClick={(e) => {
                            e.target.disabled = true;
                            setSigninDisable(true);
                            // validate();
                          }}
                        >
                          Sign In
                        </div>
                      )}
                      <Link
                        className="rounded-0 btn btn-block btn-primary p-2 font-weight-medium auth-form-btn"
                        to="/register"
                      >
                        Sign Up
                      </Link>
                    </div>
                  </form>

                  <div
                    className="row justify-content-center p-2 m-0"
                    style={{ background: "#EBEEF1", fontSize: ".7rem" }}
                  >
                    Invisible reCAPTCHA by Google &nbsp;
                    <a href="/terms-and-conditions">
                      Privacy Policy and Terms of Use.
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="" style={{ fontSize: ".7rem" }}>
                <a
                  href="https://www.bumppy.com/privacy-policy/"
                  className="mx-1"
                >
                  Legal
                </a>
                <a
                  href="https://www.bumppy.com/privacy-policy/"
                  className="mx-1"
                >
                  Privacy
                </a>
                <a href="/terms-and-conditions" className="mx-1">
                  Security
                </a>
              </div>
            </div>
            <div className="row w-100">
              <div className="col-lg-6 col-md-10 col-sm-12 mx-auto">
                <p className="text-center" style={{ fontSize: ".7rem" }}>
                  © 2023 Bumppy. All rights reserved. Bumppy, Payments, Chaafo,
                  Travel, Media are registered trademarks of Bumppy Inc.
                </p>
                <p className="text-center" style={{ fontSize: ".7rem" }}>
                  Terms and conditions, features, support, pricing, and service
                  options subject to change without notice.
                </p>
              </div>
            </div>
          </div>
        ) : context.user && Object.keys(context.user).length > 0 ? (
          <Redirect to="/retailer/aeps" />
        ) : (
          <Error404 />
        )
      }
    </GlobalContext.Consumer>
  );
}

const Invalid = ({ text }) => {
  console.log("hiii");
  return (
    <div className="card-inverse-danger">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body" style={{ padding: "10px" }}>
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "./app/retailer/App";
// import "./i18n";
import "./index.css";
import "./custom.scss";
import { GlobalProvider, GlobalContext } from "./app/Context/GlobalState";
import TransactionLoader from "./app/utils/TransactionLoader";
import Login5 from "./app/user-pages/Login5";

ReactDOM.render(
  <BrowserRouter>
    <GlobalProvider>
      <GlobalContext.Consumer>
        {(context) => (
          <TransactionLoader
            text="Please Wait..."
            isActive={context.transactionLoader}
          >
            <Switch>
              <Route path="/retailer" component={App} />
              <Route path="/" component={Login5} />
            </Switch>
          </TransactionLoader>
        )}
      </GlobalContext.Consumer>
    </GlobalProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
